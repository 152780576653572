.progress {
    display: flex;
    height: 4px;
    font-size: 0.75rem;
    background-color: var(--gray-3);
}

.progress__bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: var(--gray-2);
    transition: width .6s ease;
    position: relative;
    &:before {
        content: "";
        top: 0;
        bottom: 0;
        margin: auto;
        right: -14px;
        width: 15px;
        height: 15px;
        border-radius: 100px;
        background-color: var(--gray-2);
        position: absolute;
        z-index: 99;
        display: block;
    } 
}