.banner {
  background-color: #000;
  background-size: cover;
  padding: 13.875rem 0 4em;
  margin-top: -8.8rem;

  .container {
    align-content: center;
    justify-content: center;
    height: 100%;
  }
  &__content {
    color: var(--basic-color);
    text-align: center;
    align-items: center;
    justify-content: center;
    width: clamp(37.313em, 40%, 40em);

  }
  &__title {
    font-size: max(3em, 2.5vw);
    color: var(--basic-color);
    font-weight: 700;
    text-align: center;
    margin: 0 0 1rem;
    @include for-size(tablet-portrait-down) {
      font-size: 2.5em;
    }
  }

  &__description {
    color: var(--basic-color);
    font-size: max(1.125em, 16px);
    text-align: center;
    line-height: 28px;
  }

  &__btn {
    margin: 2.5em auto 0;
  }
}

.banner-2 {
  padding: 9.438em 6.75em;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  margin-top: 0;
  @include for-size(tablet-portrait-down) {
     padding: 4.438em 1.75em;
  }

  .container {
    justify-content: flex-start;
  }

  .banner__content {
    text-align: left;
    @include for-size(tablet-portrait-down) {
      width: 100%;
    }
  }

  .banner__title {
    text-align: left;
    @include for-size(tablet-portrait-down) {
      font-size: 2em;
    }
  }
}


.banner--quem-somos {
  padding: 20rem 0 4em;

  .container {
    justify-content: flex-start;
  }

  .banner__content {
    width: clamp(200px, 80% + 21px, 560px);
    text-align: left;
  }
  .banner__description,
  .banner__title {
    color: white;
    text-align: left;
  }
  .banner__title {
    font-weight: 600;
    position: relative;
  }
  .banner__description {
    font-weight: 300;
    margin-top: 20px;
  }

}

.banner--results {
  background: var(--green-dark);

  &--modifed {
    padding: 6.875rem 0 4em;
    @include for-size(tablet-portrait-down) {
      padding: 5.875rem 0 4em
    }
  }

  .banner__content {
    padding: 0 12em;
    width: 100%;
    text-align: center;
  }
  .banner__title {
    font-weight: 800;
  }
  .search {
    padding: 10px 20px;
  }
}
