.mini-banner {
  text-align: left;
  @include for-size(tablet-portrait-down) {
    display: none;
  }
}

.btn-disclaimer {
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.9em;
  text-transform: inherit;
  padding: 1em 16px;
  width: 100%;
  text-align: center;
  background: var(--primary-dark);
  margin-bottom: 2em;
  @include for-size(tablet-portrait-down) {
    line-height: 3rem; 
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

.about {
  padding: 6rem 0;

  .container {
    display: grid;
    grid-template-columns: var(--size, 40% 60%);
    @include for-size(tablet-portrait-down) {
      --size: 100%;
      gap: 5rem;
    }
  }

  &-column {
    &:first-child {
      @include for-size(tablet-portrait-down) {
        order: 2;
      }
    }

    .logo {
      @include for-size(tablet-portrait-down) {
        text-align: center;
      }

      img {
        @include for-size(tablet-portrait-down) {
          width: 60%;
        }
      }
    }
  }

  &-title {
    font-size: 2.8rem;
    color: var(--green-dark);
    line-height: 3rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    position: relative;
    @include for-size(tablet-portrait-down) {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    &:before {
      content: '';
      position: absolute;
      width: 20rem;
      height: 4px;
      background: var(--red);
      bottom: 0;
    }
  }

  &-text {
    color: var(--gray-2);
    line-height: 1.75rem;
    font-size: 1rem;

    span {
      font-weight: 700;
    }
  }
}

.quality {
  background: #f1f3f5;
  padding: 19.938rem 0 4rem;
  position: relative;
  margin-top: -17.5rem;
  z-index: -1;
  @include for-size(tablet-portrait-down) {
    margin-top: 0; 
    padding: 5rem 0;
  }

  &-title {
    width: 65%;
    margin-left: auto;
    color: var(--gray-1);
    font-size: 2.3rem;
    line-height: 3.25rem;
    z-index: 1;
    @include for-size(tablet-portrait-down) {
      width: 100%;
      font-size: 2rem;
    }
  }

  &-effect {
    position: absolute;
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--green);
    opacity: 0.03;
    font-size: 15.688rem;
    left: 0;
    bottom: -2.5rem;
    z-index: 0;
    @include for-size(tablet-portrait-down) {
      display: none;
    }
  }
}

.numbers {
  padding: 6rem 0;

  &-title {
    text-align: center;
    color: var(--green-dark);
    font-size: 2.5rem;
    margin-bottom: 1rem;
    width: 100%;
    @include for-size(tablet-portrait-down) {
      text-align: left;
    }
  }
 
  &-text {
    color: var(--gray-2);
    text-align: center;
    width: 80%;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1.875rem;
    @include for-size(tablet-portrait-down) {
      text-align: left;
      width: 100%;
    }
  }
}

.banner-about {
  background-image: url('/images/banner-about.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 3.063rem 0;

  &__title {
    width: 100%;
    text-align: center;
    color: var(--gray-6);
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.75rem;

    span {
      font-weight: 700;
    }
  }
}