.tabelas__content {
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  padding: 2em;
  grid-template-columns: repeat(5, 1fr);
}
.tabela__actions {
  margin-left: auto;
}
.card-tables__item {
  text-align: center;
  font-size: 13px;
  margin: 15px 0;
  svg {
    margin: auto;
  }
}
.card-tables__title {
  margin-top: 10px;
}
.loading{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background-color: white;
}
.loading.active {
  display: block;
}
.actions-page {
  margin-left: auto;
}
.actions-page {
  padding-bottom: 20px;
  border-bottom: 1px solid #DDD;
}
.btn-default {
  background-color: #930c1a;
  padding: 7px 22px;
  border-radius: 4px;
  border: 0;
  color: white;
  font-weight: 600;
  cursor: pointer;
  margin-top: 30px;
  margin-left: auto;
  display: block;
}

.tabela-page--header {
  margin-top: 4.4em;
}
