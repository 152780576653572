.dropdown__select {
  width: 100%;
  position: relative;
  &.active {
    .dropdown__select-list {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      z-index: 99;
      border-radius: 4px;
    }
  }
  .selLabel {
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: var(--gray-2);
    font-size: 14px;
    background: white;
    padding: 0 15px;
    margin-top: 5px;
    position: relative;
    z-index: 50;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: 400;
    justify-content: space-between;
    .icon {
      --size: 12px;
      margin-left: 10px;
    }
    &.active {
      .selLabel:after {
        transform: rotate(0deg);
      }
    }
  }
  &-item {
    display: block;
    list-style: none;
    left: 0;
    opacity: 1;
    transition: transform 300ms ease;
    position: relative;
    top: 0;
    width: 100%;
  }
  &-list {
    background-color: white;
    position: absolute;
    top: auto;
    width: 100%;
    margin-top: 0;
    box-shadow: 1px 0px 7px -1px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    z-index: 99;
    font-size: 14px;
    padding: 15px 0;
    li {
      padding: 10px 20px;
      color: var(--cinza);
      cursor: pointer;
      &:hover {
        background-color: #DDD
      }
    }
  }
}

