@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) { @content; }
  } @else if $size == tablet-portrait-down {
    @media (max-width: 768px) { @content; }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) { @content; }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }
}
/* Calculates maximum width for post content */
@mixin postContentMaxWidth() {

  @include media(tablet) {
    max-width: $size__site-tablet-content;
  }

  @include media(desktop) {
    max-width: $size__site-desktop-content;
  }
}

