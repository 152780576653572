.information__top {
  @include for-size(tablet-portrait-down) {
    display: none;
  }

  &-content {
    display: grid;
    grid-template-columns: var(--size, 50% auto);
    justify-content: space-between;
    align-items: center;
    background: var(--gray-6);
    width: 100%;
    padding-left: 0.813rem;
  }

  &-item {
    display: flex;
    align-items: center;
  }

  &-link {
    margin: 0 5px;

    &:nth-child(2) {
      margin-right: 20px;
    }
  }

  &-figure {

    img {
      width: 1.375rem;
      height: 1.5rem;
      margin-right: 8px;
    }
  }

  &-title {
    color: var(--gray-2);
    font-size: 0.875rem;
    font-weight: 400;
  }

  &-button {
    width: 12.438rem;
    height: 2.5rem;
    background: var(--red);
    color: var(--basic-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: filter 0.2s;
    
    &:hover {
      filter: brightness(0.9);
    }
  }
}

.header {
  border-bottom: 1px solid #f2f2f2;

  @include for-size(tablet-portrait-down) {
    border-top: 4px solid var(--primary-dark);
  }

  .container {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    @include for-size(tablet-portrait-down) {
      margin-top: 1rem;
    }

  }

  .logo {
    img {
      max-width: 133px;
    }
  }
}

