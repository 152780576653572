.btn {
  // width: var(--width, 18.313em);
  padding: var(--padding, 1.125em 1.5em);
  border: var(--btn-border, 0px solid transparant);
  background: var(--red);
  display: inline-flex;
  color: var(--btn-color, #FFF);
  font-size: var(--font-size, 0.875em);
  align-items: center;
  cursor: pointer;
  line-height: 24px;
  font-weight: 700;
  transition: ease 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
  .icon {
    fill: var(--fill, white);
    --size: 15px;
    margin-right: 5px;
  }
}

.btn-link {
  display: block;
  margin: 10px;
  font-size: 11px;
  text-decoration: underline;
}
