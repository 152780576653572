.sidebar {
  width: 100%;
}
.sidebar__search {
  border-radius: 10px;
  background-color: #D8443C;
  color: white;
  padding: 2em 1.5em;
  &--bottom {
    padding: 1.5em;
  }

  .input-group label {
    --color: white;
  }
}
