.contact__bar {
  width: 100%;
  height: 60px;
  z-index: 99;
  background-color: var(--primary-dark);
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.4);
  opacity: 0;
  transition: opacity .5s;
  padding: 5px 0;
  &.sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
  }
  .container {
    align-items: center;
    height: 100%;
    @include for-size(tablet-portrait-down) {
      justify-content: space-between;
    }
  }
  .icon {
    fill: white;
    margin-right: 10px;
    --size: 25px;
    @include for-size(tablet-portrait-down) {
      margin-right: 0;
    }
  }
  &-logo {
    margin: 0;
    img {
      width: 70px;
      filter: brightness(0) invert(1);
    }
  }
  &-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(100% / var(--items, 5) );
    color: #fff;
    position: relative;
    @include for-size(tablet-portrait-down) {
      width: 60px;
    }
    &.first {
      border-right: 1px solid #136786;
      @include for-size(tablet-portrait-down) {
        display: none;
      }
    }
  }
  &-content {
    @include for-size(tablet-portrait-down) {
      display: none;
    }
    &--first {
      font-weight: 700;
      font-size: 14px;
    }
  }
  &-label {
    width: 100%;
    font-size: 10px;
    margin-bottom: -5px;
  }
  &-value {
    font-size: 14px;
    line-height: 16px;
    font-weight: 900;
  }
}
