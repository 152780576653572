.section__about {
  margin: 2em 0;
  .heading__title{
    padding-bottom: 10px;
    --font-size: 2.4em;
    font-weight: 300
  }
  &-content {
    display: grid;
    grid-gap: 2em;
    padding: 2em 0;
    @include for-size(tablet-portrait-up) {
      grid-template-columns: 1fr 1fr;
    }
    @include for-size(desktop-up) {
      grid-template-columns: auto max-content;
    }
  }
  &-description {
    font-size: 1em;
    color: var(--cinza);
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.75px;
    text-align: left;
  }
  &-figure {
    img {
      width: 25em;
      @include for-size(tablet-portrait-down) {
        width: 100%;
      }
    }
  }
}

.section__cta {
  background-image: url('/banner-cta.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3em 2em;
  color: white;
  &-title {
    width: 100%;
    font-size: 2.9em;
  }
  &-description {
    width: 100%;
    margin-bottom: 2em;
    font-size: 0.9em;
  }
  .container {
    justify-content: center;
    text-align: center;
  }
  &-btn {
    --btn-background: transparent;
    --btn-border: 1px solid white;
  }
}

.section__missao {
  margin: 2em 0 6em;
  &-item {
    width: calc(100% / var(--items, 2) - 20px);
    margin: 10px;
    display: flex;
    align-items: center;
    @include for-size(tablet-portrait-down) {
      flex-wrap: wrap;
      --items: 1;
      margin: 25px 10px;
    }
  }
  &-image {
    padding: 0 10px;
    img {
      max-width: 80px;
    }
  }
  &-content {
    padding: 0 2em;
    flex-basis: 450px;
    @include for-size(tablet-portrait-down) {
      padding: 0;
    }
  }
  &-title {
    font-size: 20px;
    letter-spacing: 1px;
    color: #14142B;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &-description {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: var(--cinza);
    margin: 0;
  }
}

.timeline {
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 5px;
    background-color: #000;
    top: 0.6em;
    position: absolute;
  }
}
