.info__tecnicas {
  .heading__title {
    width: 100%;
    font-weight: 700;
    padding-bottom: 15px;
  }
  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    @include for-size(tablet-portrait-down) {
      grid-template-columns: 1fr;
    }
  }
  &-title {
    color: var(--primary-color);
    font-size: 19px;
    margin: 0px 0px 20px;
    display: block;
    font-weight: 600;
  }
  &-item {
    padding: 15px 0;
    border-bottom: 1px solid #EFF0F6;
    font-size: 14px;
    color: #4E4B66;
    display: inline-flex;
    width: 100%;
    span {
      font-weight: 600;
      max-width: 215px;
      min-width: 180px;
      display: inline-flex;
      vertical-align: middle;
    }
  }
}
