.results__banner {
  padding: 1.6em 0;
  background-color: var(--primary-dark);
  &-title {
    color: white;
    font-size: 1.6em;
    font-size: 24px;
    letter-spacing: 1px;
  }
}

.property__list-container {
  .container {
    display: grid;
    grid-template-columns: 19em auto;
    grid-gap: 40px;
    // grid-template-columns: max-content auto;
  }
  .property__list-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    &[data-visualization="list"] {
      .cards {
        --items: 1;
        display: grid;
        grid-template-columns: auto 1fr;
        border: 0;
        border-radius: 6px;
        margin: 0 0 2em;
        &:hover {
          box-shadow: 0 4px 16px 0 rgba(0,0,0,.12);
        }
        &__items {
          justify-content: flex-start;
          font-size: 13px;
          span {
            margin-right: 19px;
          }
        }
        &__title {
          font-size: 21px;
        }
        &__figure {
          width: 290px;
          height: 214px;
          overflow: hidden;
        }
        &__content {
          padding: 0 20px;
        }
        &__flag {
          display: none;
        }
      }
    }
  }


}
