.input-group {
  border: 0;
  margin: 0;
  padding: 0;
  width: var(--width, 100%);
  display: block;

  &--variation {
    display:  grid;
    grid-template-columns: 2fr 1fr;
  }


  label {
    width: 100%;
    display: block;
    color: var(--color, #4E4B66);
    font-weight: 600;
    font-size: 14px;
    padding-left: 3px;
  }
}
// dropdown prices

.dropdown__price-group {
  display: flex;
  flex-wrap: wrap;
  &-label {
    position: relative;
    cursor: text;
    display: flex;
    height: 55px;
    width: 100%;
    margin: 10px 15px;
    color: #222222;
    background-color: white;
    border-radius: 8px;
    box-shadow: #b0b0b0 0px 0px 0px 1px inset;
    font-size: 16px;
    line-height: 20px;
  }
  &-value {
    display: flex;
    opacity: 1;
    color: var(--cinza);
    font-weight: 300
  }
  &-legend {
    position: absolute;
    top: 18px;
    left: 12px;
    right: 12px;
    margin: 0px;
    padding: 0px;
    color: #717171;
    pointer-events: none;
    transform-origin: 0% 0%;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 20px;
    transition: -ms-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, -webkit-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    transform: translateY(-8px) scale(0.75);
    font-weight: 400;
  }
  &-symbol {
    padding-top: 28px;
    padding-left: 9px;
  }
  &-form-control {
    width: 100%;
    border: none;
    outline: none;
    padding: 0px;
    margin: 28px 5px 10px;
    min-height: 1px;
    color: inherit;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    appearance: none;
  }
}

// input like iphone
.switch__container {
  margin: 15px auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.switch {
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
}
.switch__title {
  font-size: 15px;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch--shadow + label {
  padding: 2px;
  width: 51px;
  height: 30px;
  background-color: white;
  border-radius: 60px;
  margin: 0 10px;
}
.switch--shadow + label:before,
.switch--shadow + label:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  content: "";
}
.switch--shadow + label:before {
  right: 0;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition: background 0.4s;
}
.switch--shadow + label:after {
  width: 25px;
  height: 25px;
  top: 2px;
  left: 5px;
  background-color: #D8443C;
  border-radius: 100%;
  transition: all 0.4s;
}
.switch--shadow:checked + label:before {
  background-color: var(--primary-color-dark);
}
.switch--shadow:checked + label:after {
  transform: translateX(19px);
  background-color: white;
}


.pills__group {
  display: flex;
  align-items: center;
  margin: 8px 0;
  border-radius: 8px;
  overflow: hidden;
  height: 45px;
  align-items: center;
  border: 0;
  background-color: #fff;
  &-item {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    &:before {
      content: " ";
      height: 20px;
      width: 1px;
      background-color: #d8e2e4;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
    }
    button {
      background-color: inherit;
      color: #A0A3BD;
      width: 100%;
      height: 100%;
      outline: none;
      font-size: 15px;
      font-weight: 400;
      border: 0;
    }
  }
}



// custom radio
.radio-custom-group {
  width: 270px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}
.radio-custom {
  font-size: 14px;
  display: block;
  font-weight: 200;
  padding: 20px 2em;
  text-align: center;
  text-transform: initial;
  width: 50%;
  padding: 10px;
  transition: background 0.2s, color 0.2s;
  background-color: white;
  color: #A0A3BD;
  border: 1px solid var(--gray-3);

  &:first-of-type {
    margin-left: 0;
    position: relative;
  }

  &:hover { cursor: pointer; }

  label {
    color: var(--cinza);
  }
}

input[type="radio"] {
  display: none;

  &:checked + label {
    background: var(--green-dark);
    color: #fff;
  }
}




