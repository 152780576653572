.breadcrumbs{
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0;
  &__item--current{
    color: #949494;
  }
  &-separator {
    margin: 0 5px;
  }
}
