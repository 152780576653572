:root {
  --green: #3AAB93;
  --green-light: #D9F2ED;
  --green-dark: #153E35;

  --red: #AC441E;
  --red-light: #F7DDD4;
  --red-dark: #57220F;

  --primary: #3AAB93;
  --primary-dark: #153E35;
  --primary-light: #CFF0FC;

  --gray-1: #212925;
  --gray-2: #7C8380;
  --gray-3: #B3B7B5;
  --gray-4: #DFE5E2;
  --gray-5: #F1F5F3;
  --gray-6: #F8FAF9;

  --basic-color: #FFFFFF;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  transition: transform 0.4s cubic-bezier(0.75, 0, 0.25, 1),-webkit-transform 0.4s cubic-bezier(0.75, 0, 0.25, 1);
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.75px;
}

textarea {
  border: 1px solid var(--gray-4) !important;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

ul {
  padding-left: 0;
}

button {
  cursor: pointer;
}

h1,
h2,
h3 {
  margin: 0;
}
figure {
  margin: 0;
}
.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.container {
  @extend .display-flex;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media(min-width: 1200px) {
    max-width: 1170px;
  }
  @media(min-width: 1400px) {
    max-width: 1300px;
  }
}
.icon {
  --size: 25px;
  width: var(--size);
  height: var(--size);
}

.overlay-menu {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.7686274509803922);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 55;
}
.show-menu  .overlay-menu {
  display: block;
}

.heading {
  width: 100%;
  &__title {
    color: var(--color, var(--green-dark));
    font-size: max(2.6em, 2.5vw);
  }
}

.overlay{
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.7686274509803922);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 55;
}

#login {
  display: none;
}
#signup {
  display: none;
}

#login.active{
  display: block;
}

#signup.active {
  display: block;
}
.show-password {
  position: relative;

  .form-control {
    margin-bottom: 0!important;
  }
}
.show-password__btn {
  position: absolute;
  right: 10px;
  top: 5px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    fill: #636161;
  }
  &.active {
    .icon--aberto {
      display: none;
    }
    .icon--fechado {
      display: block;
    }

  }

  .icon--aberto {
    display: block;
  }
  .icon--fechado {
    display: none;
  }

}
.lightbox {
  overflow-y: scroll;
  height: 100%;
}

.renove-utm {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: var(--red);

  a {
    display: inline-block;
  }
}

@-webkit-keyframes animate-svg-fill {
  0% { fill: transparent }
  100% { fill: white}
}

@keyframes animate-svg-fill {
  0% { fill: transparent }
  100% { fill: white }
}

.svg-elem-1 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
    animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
}
.svg-elem-2 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.9s both;
    animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.9s both;
}

.svg-elem-3 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
    animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}
.svg-elem-4 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.1s both;
    animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.1s both;
}
.svg-elem-5 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.2000000000000002s both;
    animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.2000000000000002s both;
}
.svg-elem-6 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.3s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.3s both;
}
.svg-elem-7 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.4000000000000001s both;
    animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.4000000000000001s both;
}

.svg-elem-8 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
    animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
}

.svg-elem-9 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.6s both;
    animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.6s both;
}
