.arch-slider {
  margin: 0;
  background-color: white;
  padding: 0;
}
.arc-images {
  position: relative;
    &:before {
    content: "";
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0;
    z-index: 1
  }
}

a.arc-link:hover .arc-images:before {
  background: rgb(247 247 247 / 14%);

}

.architecturalmillwork-lyt .slick-lightbox-close {
  color: rgba(0,110,158,1);
  font-size: 26px;
  width: 38px;
  height: 44px;
  margin-top: 30px;
  margin-right: 80px;
  right: 0;
  top: 2px
}

.arch-slider .slick-arrow {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  margin-top: -55px;
  z-index: 111;
  padding: 20px 38.5px;
  cursor: pointer
}

.arch-slider .slick-arrow.next {
  right: 0
}

.arch-slider .slick-arrow svg {
  -webkit-filter: drop-shadow(0 1px .15px #b2b2b2);
  filter: drop-shadow(0 1px .15px #b2b2b2);
  display: inline-block
}

.slideshow-arrow {
  fill: #e8e8e8
}

.architecturalmillwork-lyt .slick-lightbox-inner {
  background-color: rgba(232,232,232,1);
  z-index: 1111
}

.architecturalmillwork-lyt .slick-lightbox-inner .slick-arrow {
    z-index: 111;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 27px;
    margin-top: -50px;
    &:before {
      display: none
    }
}
.architecturalmillwork-lyt .slick-lightbox-inner .slick-prev {
  left: 0
}
.architecturalmillwork-lyt .slick-lightbox-inner .slick-next {
  right: 0;
  transform: rotate(180deg)
}
// modal slick
