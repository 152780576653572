.section {
  margin: 6em 0;
}

.section__property-list {

  .heading {
    width: 100%;
    margin-bottom: 4.25em;
    text-align: center;

    &__title {
      position: relative;
      text-align: left;

      &--modifed {
        text-align: center;
        @include for-size(tablet-portrait-down) {
          text-align: left;
        }
        &:before {
          content: '';
          position: absolute;
          width: 7em;
          height: 0.15em;
          background: var(--red);
          bottom: -0.7em;
          left: 10em;
          @include for-size(tablet-portrait-down) {
            display: none;
          }
        }
      }
    }
  }
  .heading__description {
    color: var(--gray-2);
    padding-top: 1em;
    font-size: max(1.1em, 1.3vw);
    margin: 0;
    font-weight: 300;
    font-size: 17px;
    text-align: left;
  }
  .btn {
    align-items:center;
    display: inline-flex;
    .icon {
      fill: white;
      --size: 14px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }
}

.section__enterprise-list {
  // background-color: #14142B;
  padding: 2em 0;
  padding-bottom: 0;
  .heading {
    width: 100%;
    margin-bottom: 2em;
    display: grid;
    align-items: center;
    // color: white;
    color: #4E4B66;
    grid-template-columns: 1fr 1fr;
    position: relative;
    @include for-size(tablet-portrait-down) {
      grid-template-columns: 1fr;
      .heading__title {
        text-align: left;
      }
    }
  }
  .heading__title {
    text-align: right;
    margin-right: 50px;
    color: #4E4B66;
    font-weight: 300;
    --border: 0;
    b {
      color: var(--primary-color);
    }
  }
  .heading__description {
    color: #4E4B66;
    padding-top: 10px;
    max-width: 24em;
    border: 0;

    &:before {
      content: '';
      width: 60%;
      height: 1px;
      bottom: -20px;
      position: absolute;
      background-color: #EFF0F6;
      right: auto;
      @include for-size(tablet-portrait-up) {
        right: -60%;
        margin-right: 50%;
      }
    }
    strong {
      color: var(--primary-color);
    }
  }
}
.section__blog-list {
  margin: 4em 0;
}

.maps-heading {
  width: 100%;
  .heading__title {
    padding: 10px 0;
  }
  .heading__description {
    font-size: 14px;
    color: var(--gray-3);
  }
}

.carrousel-building {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .slick-arrow {
    top: 0;
    bottom: 0;
    margin: auto;
    position:absolute;
    height: 60px;
    &.prev {
      left: -50px;
    }
    &.next {
      right: -50%;
    }
  }
}




