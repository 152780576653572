.gallery {
  padding: 4em 0;
  margin-top: 3em;

  &--all {
    background: var(--red);

    .heading__title {
      color: var(--basic-color);
    }

    .heading__description {
      color: var(--basic-color) !important;
    }
  }

  .heading{
    text-align: center;
    &__title {
      font-weight: bold;
    }
    &__description {
      color: var(--gray-2);
      font-size: 16px;
    }
  }

  &__grid {
    width: 100%;
    margin-top: 2em;
    position: relative;
    img {
      max-width: 100%;
      height: 28em;
    }
  }
  &-title {
    margin-bottom: 4em;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 22px;
  }
  &-container{
    width: 90%;
    margin-top: 2em;
    margin-bottom: 0em;
    @include for-size(tablet-portrait-down){
      flex-wrap: wrap;
    }
  }
  .section-title {
    width: 100%;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6em;
    margin-top: 0;
  }
  &-indice {
    width: 100%;
    display: flex;
    justify-content: center;
    @include for-size(tablet-portrait-down) {
      white-space: nowrap;
      overflow-x: auto;
      padding: 15px;
    }
    &__link {
      padding: 5px 15px;
      margin: 5px;
      border: 1px solid transparent;
      color: var(--basic-color);
      &.active {
        border: 1px solid var(--basic-color);
      }
    }
  }

}

.gallery--all {
  // background-color: rgb(247, 247, 252);
  .slick-slide {
    margin: 0 20px;
  }
  .slick-slider {
    display: none;
    transition: all 1.2s;
    &.active {
      display: block;
    }
  }

}
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}


.gallery__plantas {
  width: 100%;
  padding: 2em 0;
  .heading__title {
    text-align: center;
  }
}

.gallery__plantas-list {
  width: 100%;
  margin: 3em auto;
  position: relative;
  outline: none;
  .slick-slide {
    margin: 0 20px;
  }
  
  .slick-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &.prev {
      left: -30px;
    }
    &.next {
      right: 0;
    }
  }

  img {
    max-width: 430px;
    margin: 0 auto;
  }
}
.slick-slide {
  outline: 0;
}

.gallery__row,
.gallery__plantas-list{
  .slick-next.slick-arrow,
  .slick-prev.slick-arrow {
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 99;
    fill: white;
  }
  .slick-dots {
    display: inline-flex;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .slick-dots li button {
    font-size: 0;
    width: 10px;
    display: inline-flex;
    height: 10px;
    border: 0;
    margin: 0 5px;
    height: 10px;
    padding: 0;
    border-radius: 100px;
  }
  .slick-dots .slick-active button {
    background-color: #f5e63d!important;
  }
  .slick-prev.slick-arrow {
    left: 10px;
  }
  .slick-next.slick-arrow {
    right: 10px;
  }

}
.gallery__row {
  .slick-dots button {
    background-color: white;
  }
}
.gallery__plantas-list {
  .slick-dots button {
    background-color: #DDD;
  }
  .slick-dots .slick-active button {
    background-color: var(--red)!important;
  }
}


