.modal {
  width: var(--width, 450px);
  height: var(--height, 370px);
  border-radius: 6px;
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: none;

  &__body {
    padding: 2em;
  }

  .form-header {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .form-control{
    border: 1px solid #DDD;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .input-group label {
    font-weight: 500;
    font-size: 12px;
  }

  .btn-link {
    color: #9e9e9e;
  }
}
.modal-is-active {
  .modal {
    display: block;
    z-index: 60;
    width: 99%;
    margin: auto;
    position: fixed;
    max-width: 470px;
  }
  .overlay--modal {
    display: block;
  }
}

.modal__access--big {
  --height: 610px;
}
