.footer {
  background: linear-gradient(180deg, #153E35 0%, #212925 100%);
  color: white;

  &__column {
    @include for-size(tablet-portrait-down) {
      padding: 20px;
    }
    @include for-size(tablet-portrait-up) {
      &:last-child {
        grid-column-end: none;
      }
    }

    &-title {
      color: var(--green);
      font-weight: 700;
      margin-bottom: 1.5rem;

      &--modifed {
        color: var(--basic-color);
        font-weight: 400;
        text-align: left;
        font-size: 13px;
        margin-top: 20px;
        margin-bottom: 1.5rem;
      }
    }

    &-item {
      font-size: 14px;
      line-height: 160%;
      margin-bottom: 1rem;
      display: block;

      a {
        display: inline-flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }
      }

    }

    &-content {
      font-size: 14px;
      line-height: 160%;
      color:var(--gray-6);
      margin-top: 0;
      margin-bottom: 2.5rem;
    }
    &-logo {
      text-align: left;
      img {
        width: 12em;
        filter: brightness(0) invert(1);
      }
    }
    .icon {
      --size: 25px;
      fill: white;
      margin-right: 10px;
    }

  }

  .container {
    display: grid;
    column-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(var(--width, 120px), 1fr));
    width: 100%;
    padding: 4em 0 2em;
    @include for-size(tablet-portrait-down) {
      grid-template-columns: 1fr;
    }
  }
  .copyright {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 2em;
    color: #D9DBE1;
    font-size: 13px;
  }
}

.btn-floating-whatsapp {
  width: 90px;
  height: 90px;
  position: fixed;
  bottom: 2em;
  right: 1.5em;
  cursor: pointer;
  text-align: center;
  line-height: 115px;
  background-color: #25d366;
  border-radius: 100%;
  animation: pulse 3s infinite;
  z-index: 9999;
  .icon {
    fill: white;
    --size: 40px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
