.results__summary {
  .heading__title {
    --font-size: 24px;
    padding-bottom: 13px;
    color: var(--primary-color);
  }
}

.page__enterprise-list{
  padding: 4rem 0;
}