.search {
  display: flex;
  flex-wrap: wrap;
  width: var(--width, 100%);
  padding: 20px;
  padding-left: 0;

  form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    background-color: white;
  }
  .input-group {
    --width: 100%;
    border: 0.756892px solid #DFE5E2;
    @include for-size(tablet-portrait-up) {
      --width: 300px;
    }
  }

  .btn{
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-left: 15px;
    }
  }
}

.input-group__btn-wrapper {
  @include for-size(tablet-portrait-down) {
    width: 100%;
  }
}
