.single__page{
  &-content {
    padding: 2em 5em 2px 1em;
  }
  .container {
    display: grid;
    grid-template-columns: auto max-content;
  }
  &-title {
    color: var(--primary-color);
    font-size: 36px;
    font-weight: 400;
    line-height: 48px;
    position: relative;
  }
  &-ref {
    color: #A0A3BD;
    font-size: 11px;
    text-align: right;
    border-bottom: 1px solid #EFF0F6;
    padding-bottom: 10px;
  }
  &-description {
    font-weight: 300;
    line-height: 38px;
    color: #4E4B66;
    display: grid;
    grid-template-columns: max-content auto;
    align-items: flex-start;
    margin: 15px 0;
    &-text {
      font-size: 16px;
      line-height: 25px;
      max-width: 520px;
       p {
        margin: 0;
      }
    }
    .icon {
      --size: 15px;
      margin-right: 10px;
      margin-top: 7px;
    }
    .btn {
      --padding: 0 20px;
      --font-size: 10px;
      font-weight: 600;
      height: 25px;
      line-height: 25px;
      margin-left: 10px;
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    text-align: center;

    .icon {
      margin: 0 auto;
      --size: 35px;
    }
  }
  &-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 1.3em;
    margin-top: 1.3em;
    @include for-size(tablet-portrait-down) {
      width: calc(100% / 4);
    }
  }
  &-label {
    width: 100%;
    margin: 15px 0;
    font-size: 16px;
    line-height: 19px;
    color: #4E4B66;
  }

  .about__property {
    margin-top: 2em;
    .heading__title {
      border-bottom: 0;
    }

    &-description {
      font-size: 15px;
      line-height: 34px;
      color: #4E4B66;
    }
    .btn {
      --btn-background: transparent;
      --btn-color: var(--primary-color-light);
      --btn-border: 1px solid var(--primary-color-light);
      --padding: 15px 25px;
      font-weight: 600;
      align-items: center;
      margin-top: 2em;
      .icon {
        fill: var(--primary-color-light);
        --size: 14px;
        margin-right: 10px;
      }
    }

  }

  .sidebar {
    width: 24em;
    padding: 20px;
  }
}
.single-banner {
  position: relative;
}
.btn-open-gallery {
  --btn-color: var(--primary-color-light);
  --btn-background: white;
  display: inline-flex;
  align-items: center;
  position: absolute;
  left: 2em;
  bottom: 20px;
  --font-size: 12px;
  .icon {
    fill:var(--primary-color-light);
    margin-right: 5px;
    --size: 15px;
  }
}
.single__page-more-info {
  font-size: 12px;
  color: #4E4B66;
  display: inline-flex;
  align-items: center;
  margin: 30px 0;
  font-weight: 600;

  @include for-size(big-desktop-up) {
    align-content: center;
  }

  .icon {
    fill: #4E4B66;
    --size: 10px;
    margin-right: 5px;
  }
}

.single__sidebar {
  background-color: #F7F7FC;
  &-header {
    color: white;
    background-color: var(--primary-color);
    padding: 15px 30px;
    font-weight: 600;
  }
  &-price {
    padding: 20px 30px;
    font-family: 'Lato', sans-serif;
  }
  &-price-label {
    font-size: 13px;
    color: #6E7191;
    margin-bottom: 5px;
  }
  &-price-value {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 2.8em;
  }
  &-list {
    padding: 15px 30px;
  }
  &-item {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
  }
  &-value {
    font-weight: 600
  }
  .btn-group {
    padding: 0 30px;
  }
  .btn-phone,
  .btn-whatsapp {
    width: 100%;
    --padding: 15px 20px;
    justify-content: center;
    .icon {
      --size: 18px;
      margin-right: 5px;
    }
  }
  .btn-phone {
    --btn-background: transparent;
    --btn-border: 1px solid var(--primary-color-light);
    --btn-color: var(--primary-color-light);
    align-items: center;
    margin-top: 20px;
    .icon {
      fill: var(--primary-color-light);
      --size: 12px;
    }
  }
  .btn-whatsapp {
    --btn-background: #00BA88;
    .icon {
      fill: white;
    }
  }
}

.localization {
  display: grid;
  grid-template-columns: var(--size, 35% 65%);
  @include for-size(tablet-portrait-down) {
    --size: 100%;
  }

  #map {
    margin: 0;
  }

  &-column {

    &:first-child {
      padding: 4rem 5rem;
      background: var(--gray-6);
      @include for-size(tablet-portrait-down) {
        padding: 4rem 3rem;
      }
    }
  }

  .heading__title {
    padding-top: 0;
    padding-bottom: 1.125rem;
    margin-bottom: 1.125rem;
    border-bottom: 3px solid var(--red);
  }

  .heading__description {
    color: var(--gray-2);
    margin-bottom: 2rem;
  }
}